.navbar {
    display: flex;
    justify-content: space-between; 
    align-items: center; 
    padding: 10px; 
  }
  
  .navbar-left {
    flex: 1; 
  }
  
  .navbar-right {
    display: flex; 
    gap: 10px; 
  }
  
  .btn-primary {
    background: linear-gradient(269.16deg, #14F7CC -9.58%, #440D8D 43.58%, #00109F 75.94%);
    color: white;
    border: none;
    /* width: 130px;  */
    height: 40px;
    border-radius: 5px;
  }
  
  /* .btn-danger {
    width: 120px; 
  } */
  .page-name{
    font-weight: bold;
  }
  .navbar-bg{
    background: linear-gradient(269.16deg, #14F7CC -9.58%, #440D8D 43.58%, #00109F 75.94%);
    .profile-dropdown{
      color: #FFFFFF;
    }
  }