.sidebar {
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.2);
  overflow-x: hidden;
  overflow-y: auto;
}

.top_section {
  display: block;
  align-items: center;
  padding: 10px;
  width: 100%;
  min-height: 70px;
}
li.nav-item {
  width: 100%;
  position: relative;
}
.bars {
  cursor: pointer;
  margin-left: 20px; 
}

.caret{
  position: absolute;
  right: 10px;
  top: 8px;
}
.sidebar.collapsed .caret, .sidebar.collapsed  .sub-nav.nav{
  display: none !important;
}
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 999;
}

.link {
  display: flex;
  align-items: center;
  padding: 10px;
  color: #fff;
  text-decoration: none;
  transition: background 0.3s ease;
}

.link:hover, .link.active {
  background: rgba(255, 255, 255, 0.2);
}
ul.sub-nav.nav {
  position: relative;
  margin-left: 25px;
}
.icon {
  font-size: 20px;
  margin-right: 10px;
  margin-top: -5px;
}
.sub-nav-icon{  
  font-size: 14px;
}
.link_text {
  font-size: 16px;
}
ul.sub-nav.nav .link_text {
  font-size: 14px;
}
@media (min-width:2000px){
  .sidebar{
    display: none!important;;
  }
  main{
    margin-left: 0!important;
  }
}