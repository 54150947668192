.login-container {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    padding: 20px;
}

.login-content {
    display: flex;
    flex-direction: column;
    max-width: 1000px;
    width: 100%;
    border: 1px solid #ddd;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 1px 0 5px rgba(0, 0, 0, 0.2);
    background: #440D8D;
}

.sideImg {
    width: 100%;
    height: auto;
    object-fit: cover;
    background-color:#0D009F
}

.form-container {
    width: 100%;
    padding: 20px;
    background: #fff;
}

.power-hub {
    font-weight: bold;
    color: #616161;
}

.otp-text {
    font-size: small;
    color: #616161;
}

.error-text {
    font-size: x-small;
    color: #616161;
}

.button-container {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
}

/* Medium screens (≥768px) */
@media (min-width: 768px) {
    .login-content {
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 20px
    }

    .sideImg {
        width: 50%;
        height: auto;
       background-color:#0D009F
    }

    .form-container {
        width: 100%;
        padding: 20px;
    }
}

@media (min-width: 992px) {
    .login-content {
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 30px
    }

    .sideImg {
        width: 70%;
        height: auto;
        background-color:#0D009F
    }

    .form-container {
        width: 50%;
        padding: 40px;
    }
}


