ul.ant-pagination.ant-pagination-mini:not(.ant-pagination-disabled) {
    li:not(.ant-pagination-total-text){
        background-color: #440D8D;
        border: 1px solid #440D8D;
        color: #FFFFFF;
        margin: 0 2px;
        border-radius: 3px;
        button{
            color: #FFFFFF;
            line-height: 1;
        }
    }
    li.ant-pagination-item.ant-pagination-item-active{
        background-color: #14F7CC;
        border: 1px solid #14F7CC;
        color: #FFFFFF;
    }
    li.ant-pagination-item:not(.ant-pagination-item-active){
        background-color: #440D8D;
        border: 1px solid #440D8D;
        color: #FFFFFF;
    }
    li.ant-pagination-jump-prev .ant-pagination-item-ellipsis, li.ant-pagination-jump-next .ant-pagination-item-ellipsis{
        line-height: 1.6;
        color: #FFFFFF;
    }
    li.ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-link-icon, li.ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-link-icon{
        line-height: 1.6;
        color: #FFFFFF;
        font-size: initial;
    }
}
.ant-form-item{
    margin-bottom: 0;
}
.ant-modal-footer{
   text-align: end;
}
.ant-modal-footer button{
    width: auto;
    display: inline-block;
    margin-left: 15px;
}
.images-block-upload{
    position: relative;
    .ant-image{
        position: absolute;
        top: 4px;
        right: 0;
        background: rgba(0, 0, 0, 0.5);
    }
}
.tabbar-top.nav-pills{
    .nav-item{
        padding: 0 0 0 10px;
        .nav-link{
            color: #fff;
            border-radius: 0;
            font-weight: 500;
            &.active{
                background-color: #14F7CC;
                color: #440D8D;
            }
        }
    }
}
.custom-tab-content.tab-content{
    padding: 20px;
    border: 1px solid #440D8D;
    background: #FFFFFF;
}
.bar-code-image{
    width: 350px;
}
.settingsBox{
    padding: 15px;
    border: 1px solid #ddd;
    border-radius: 5px;
    box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.4);
    margin-bottom: 20px;
}
.btn-info{
    color: #FFFFFF!important;
}
.suggession-dropdown{
    top: 40px;
    width: 100%;
    left: 0;
    max-height: 120px;
    overflow-x: auto;
    z-index: 99;
    li{
        cursor: pointer;
    }
}
.info-card-box-number{
    margin: 0;
}
.card.stats-cards .card-body{
    padding: 10px;
}
.bg-total-battries{
    background: #0FAEB4!important;
}
.bg-total-drivers{
    background: #652CB3!important;
}
.bg-total-partners{
    background: #9510AC!important;
}
.bg-total-revenve{
    background: #8FD14F!important;
}
.bg-total-locations{
    background: #2D9BF0!important;
}
.bg-total-recovery{
    background: #808080!important;
}
.bg-total-tickets{
    background: #ED881A!important;
}
.status-imgs{
    text-align: center;
    margin-bottom: 0;
    margin-top: 10px;
    display: block;
    span{
        font-size: 12px;
        width: 50%;
        display: inline;
        margin: 0 10px;
        img{
            width: 15px;
        }
    }
}
.icon-report{
    color: #FFFFFF;
    background: #626262;
    padding: 5px 12px;
    border-radius: 15px;
}
.iconFilter{
    cursor: pointer;
}
@media print {
    .centered-content {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100vh;
        text-align: center;
        width: 100%;
        scale: 10;
    }
}