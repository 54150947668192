.otp-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
}

.otp-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #fff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    max-width: 1000px;
    margin: 0 auto;
    background: #440D8D;
}

.sideImg {
    max-width: 100%;
    height: auto;
    margin-bottom: 20px;
}

.form-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.input-group {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.input-group input {
    border: none;
    border-bottom: 2px solid #ccc;
    padding: 8px 14px;
    margin: 0;
    outline: none;
    font-size: 14px;
    width: 22%;
    text-align: center;
}

.input-group input:focus {
    border-bottom: 1px solid #007bff;
}

.error-text {
    font-size: 14px;
    color: red;
}

.button-container {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    margin-top: 20px;
}

.resend-otp {
    font-size: small;
    font-weight: bold;
}

@media (min-width: 768px) {
    .otp-content {
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }

    .sideImg {
        margin-bottom: 0;
        margin-right: 10px;
    }

    .form-container {
        align-items: flex-start; 
    }
}

.custom-input:focus {
    outline: none; /* Remove default focus outline */
    border-color: #0D009F; /* Optional: Change border color on focus */
}
