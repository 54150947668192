@import url('assests/css/custom.css');
* {
    margin: 0;
    padding: 0;
    text-decoration: none;
    box-sizing: border-box;
}

body, html {
    width: 100%;
    height: 100%;
    overflow-x: hidden;
}

.container {
    display: flex;
    flex-direction: row;
}

main {
    flex: 1;
    padding: 20px;
    margin-left: 200px; 
    transition: margin-left 0.5s;
}

.sidebar {
    background: #000;
    color: #fff;
    height: 100vh;
    width: 200px;
    position: fixed;
    top: 0;
    left: 0;
    transition: all 0.5s;
    z-index: 1000; /* Ensure it overlays other content */
}

.top_section {
    display: flex;
    align-items: center;
    padding: 20px 15px;
}

.logo {
    font-size: 30px;
}

.bars {
    display: block;
    font-size: 25px;
    position: absolute;
    right: 10px;
    top: 0;
    line-height: 60px;
}

.link {
    display: flex;
    color: #fff;
    padding: 10px 15px;
    gap: 15px;
    transition: all 0.5s;
}

.link:hover {
    background: lightskyblue;
    color: #000;
    transition: all 0.5s;
}

.active {
    background: lightskyblue;
    color: #000;
}

.icon, .link_text {
    font-size: 20px;
}

.container-fluid {
    display: flex;
    width: 100%;
    height: 100%;
    padding: 0;
}

.table-responsive {
    overflow-x: auto;
}

.modal.show.d-block {
    display: flex !important;
    justify-content: center;
    align-items: center;
}
.card.map-containter-dashboard{
    height: calc((100vh - 60px));
}
/* Media Queries for Responsiveness */
@media (max-width: 768px) {
    /* .sidebar {
        width: 150px;
    }
    main {
        margin-left: 150px;
    }
    .logo {
        font-size: 25px;
    }
    .bars {
        font-size: 20px;
        margin-left: 30px;
    }
    .link {
        padding: 8px 10px;
        gap: 10px;
    }
    .icon, .link_text {
        font-size: 18px;
    } */
}

@media (max-width: 480px) {
    /* .sidebar {
        width: 100px;
    }
    main {
        margin-left: 100px;
    }
    .logo {
        font-size: 20px;
    }
    .bars {
        font-size: 18px;
        margin-left: 20px;
    }
    .link {
        padding: 5px 8px;
        gap: 8px;
    }
    .icon, .link_text {
        font-size: 16px;
    } */
}

@media (max-width: 640px) {
    /* .sidebar {
        width: 80px;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 1000; 
    }
    main {
        margin-left: 0; 
    }
    .logo {
        font-size: 18px;
    }
    .bars {
        font-size: 16px;
        margin-left: 10px;
    }
    .link {
        padding: 4px 6px;
        gap: 5px;
    }
    .icon, .link_text {
        font-size: 14px;
    } */
}

@media (min-width: 1600px) {
    .card.map-containter-dashboard{
        height: calc((100vh - 60px)/2);
    }
}

@media (min-width:1980px){
    .col-xxxl-3{
        width: 25%!important;
    }
    .col-xxxl-4{
        width: 33.33%!important;
    }
    .col-xxxl-8{
        width: 66.67%!important;
    }
}
