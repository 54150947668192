.preloaderBlock{
    left:0; 
    top:0; 
    background:rgba(0,0,0,0.1);
    z-index:9999999999999999999999999999999;
}
.loadingText{
    font-size: 16px;
    color: #FFF;
    font-weight: 500;
}